.info-btnAuth-location {
    display: flex;
    justify-content: center;
    margin: 25px;
}

.d-flex {
    color: grey;
    margin-bottom: 25px;
}

.payment-form-container {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.pay-para-div {
    display: flex;
    justify-content: center;
    max-width: 600px;
    text-align:justify;
    
}

.payauth-checkbox {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.payAuth-title, .contract-title {
    margin-top: 50px;
    margin-bottom: 25px;
}

.credit-card-iframe-container {
    margin-top: 20px;
    margin-bottom: 10px;
}

.spinner {
    color: #4bb543;
    display: block;
    position: fixed;
    z-index: 1031; /* High z-index so it is on top of the page */
    top: 50%;
    right: 50%; /* or: left: 50%; */
    left: calc(50% - (50px / 2))
}

.decline-message {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}


div.decline-card {
    background-color: #E4A11B;
    margin-left: 15%;
    margin-right: 15%;
    display: flex;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.20) 0px 5px 10px;
}

button.btn-payAuth {
    background-image: url('../../public/assets/LockIcon.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 50px;
}

input#formBasicCardNumber.card-type.form-control {
    background-image: url("../../public/assets/default-card.png");
    background-size: 60px;
    background-repeat: no-repeat;
    background-position: right;
}

input#formBasicCardNumber.card-type.form-control.visa {
    background-image: url("../../public/assets/visa_icon.png");
    background-size: 60px;
    background-repeat: no-repeat;
    background-position: right;
}

input#formBasicCardNumber.card-type.form-control.mastercard {
    background-image: url("../../public/assets/mastercard-icon.png");
    background-size: 60px;
    background-repeat: no-repeat;
    background-position: right;
}

input#formBasicCardNumber.card-type.form-control.amex {
    background-image: url("../../public/assets/Amex-logo.png");
    background-size: 60px;
    background-repeat: no-repeat;
    background-position: right;
}

input#formBasicCardNumber.card-type.form-control.discover {
    background-image: url("../../public/assets/Discover-logo.png");
    background-size: 60px;
    background-repeat: no-repeat;
    background-position: right;
}

input#formBasicCardNumber.card-type.form-control.diners_club_international {
    background-image: url("../../public/assets/diners-int-logo.png");
    background-size: 60px;
    background-repeat: no-repeat;
    background-position: right;
}

input#formBasicCardNumber.card-type.form-control.jcb {
    background-image: url("../../public/assets/jcb-card-logo.png");
    background-size: 60px;
    background-repeat: no-repeat;
    background-position: right;
}

