
.auth-page-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    
}
.btn-otp-cont {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.otp-group-cont {
    display: flex;
    justify-content: center;
}

.otp-control {
    max-width: 250px;
}

.verify-code-title {
    display: flex;
    justify-content: center;
    text-align: center;
}

.btn-auth {
    background-color: #4bb543;
    border: #4bb543;
    border-radius: 2px;
    width: 250px;
}

button.btn-auth.btn.btn-primary {
    display: flex;
    justify-content: center;
    background-color: #4bb543;
    border: #4bb543;
    border-radius: 2px;
    width: 250px;
    height: 38px;
    align-items: center;
}


.form-control.authEmail {
    display: flex;
    justify-content: center;
    min-width: 300px;
 
}

.form-control.authPhone {
    display: flex;
    justify-content: center;
}

.div-auth-button {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.auth-text {
    display: flex;
    justify-content: center;
    color: grey;
}

div.auth-text.container {
    padding: 0px 35px;
}

.banner-msg {
    margin-top: 25px;
}

.display-norefresh-msg-container {
    margin-top: 25px;
}

.sms-error-bar {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
}

.sms-image-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 35px;
}

.code-form-container {
    display: flex;
    justify-content: center;
}

