
.modal-title {
    background-color: #d9534f;
    display: flex;
    justify-content: center;
}

.modal-body {
    display: flex;
    justify-content: center;
    text-align: center;
}
div.modal-footer {
    padding: 0;
    background-color: lightgray;
    border-radius: 0;
}
.btn.modal-btn, .btn.modal-btn:hover {
    margin: 10px;
    border-radius: 2px;
}

.modal-message-div {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: whitesmoke;
    padding-top: 2px;
    padding-bottom: 2px;
}